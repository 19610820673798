<template>
	<v-data-table
		class="elevation-1"
		v-bind="{
			loading,
			headers,
			items: features,
			page: urlFilters.page,
			itemsPerPage: urlFilters.limit,
			footerProps: {
				itemsPerPageOptions,
				itemsPerPageText: 'Строк на стр.',
			},
			serverItemsLength: total
		}"
		@update:page="updateUrl({page: $event})"
		@update:items-per-page="updateItemsPerPage"
	>
		<template v-slot:top>
			<v-toolbar
				class="features-table-top"
				flat
			>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-divider
					class="mx-6"
					vertical
					inset
				/>
				<v-dialog
					v-model="isDialogCreate"
					max-width="500px"
					@click:outside="resetForm"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="white--text"
							v-bind="{
								...attrs,
								dark: true,
								color: 'primary'
							}"
							v-on="on"
						>
							<v-icon
								left
								dark
							>
								mdi-plus
							</v-icon>
							Добавить
						</v-btn>
					</template>
					<v-card>
						<v-card-title>Новая фича</v-card-title>
						<v-card-text>
							<v-form
								v-model="isFormValid"
								ref="form"
							>
								<v-container>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="featureNew.name"
												label="Название фичи"
												outlined
												:rules="[rules.required, rules.maxLength, rules.uniqueName]"
												hide-details="auto"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="featureNew.description"
												label="Описание"
												outlined
												hide-details="auto"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="featureNew.userIds"
												label="IDs пользователей"
												outlined
												hide-details="auto"
												:rules="[rules.arrayNumber]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="featureNew.clientIds"
												label="IDs компаний"
												outlined
												hide-details="auto"
												:rules="[rules.arrayNumber]"
											/>
										</v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn
								color="grey darken-1"
								text
								@click="resetForm"
							>
								Отменить
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								:disabled="!isFormValid || isCreateFeatureInProgress"
								:loading="isCreateFeatureInProgress"
								@click="submitForm"
							>
								Создать
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.name="{ value, item }">
			<v-label-editable
				v-bind="{
					value,
					rules: [rules.required, rules.maxLength, rules.uniqueName],
					onInput: newValue => updateFeatureData(item.id, 'name', newValue)
				}"
			/>
		</template>
		<template v-slot:item.description="{ value, item }">
			<v-label-editable
				v-bind="{
					value,
					onInput: newValue => updateFeatureData(item.id, 'description', newValue)
				}"
			/>
		</template>
		<template v-slot:item.ids="{ item }">
			<div class="features-ids">
				<div class="d-flex align-center pa-4">IDs пользователей</div>
				<div class="pt-4 pb-3 px-4">
					<v-label-editable
						:value="prepareStringIds(item.user_ids)"
						:rules="[rules.arrayNumber]"
						:on-input="newValue => updateFeatureData(item.id, 'user_ids', newValue)"
					>
						<template v-slot:fake-value>
							<v-chip
								v-for="(id, i) of item.user_ids"
								:key="`user-${id}`"
								:class="i !== item.user_ids.length - 1 && 'mr-1'"
								small
							>
								{{ id }}
							</v-chip>
						</template>
					</v-label-editable>
				</div>
				<div class="d-flex align-center pa-4">IDs компаний</div>
				<div class="pt-4 pb-3 px-4">
					<v-label-editable
						:value="prepareStringIds(item.client_ids)"
						:rules="[rules.arrayNumber]"
						:on-input="newValue => updateFeatureData(item.id, 'client_ids', newValue)"
					>
						<template v-slot:fake-value>
							<v-chip
								v-for="(id, i) of item.client_ids"
								:key="`client-${id}`"
								:class="[i !== item.client_ids.length - 1 && 'mr-1', 'mb-1']"
								small
							>
								{{ id }}
							</v-chip>
						</template>
					</v-label-editable>
				</div>
			</div>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-dialog
				v-model="isDialogDelete"
				max-width="510px"
				:retain-focus="false"
				@click:outside="closeDialogDelete"
			>
				<template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
							<v-btn
								v-bind="{
									...dialogAttrs,
									...tooltipAttrs,
									icon: true,
									small: true
								}"
								v-on="{
									...dialogOn,
									...tooltipOn,
									click: () => openDialogDelete(item.id)
								}"
							>
								<v-icon
									small
									color="grey"
								>
									mdi-delete
								</v-icon>
							</v-btn>
						</template>
						<span>Удалить</span>
					</v-tooltip>
				</template>
				<v-card>
					<v-card-title>
						Вы уверены, что хотите удалить данную фичу?
					</v-card-title>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="grey darken-1"
							text
							:disabled="isDeleteFeatureInProgress"
							@click="closeDialogDelete"
						>
							Нет
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							:disabled="isDeleteFeatureInProgress"
							:loading="isDeleteFeatureInProgress"
							@click="confirmFeatureDeletion"
						>
							Да
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validateFilterNumber, validateFilterFromOptions } from '@/function/validateFilter'

import updateUrlMixin from '@/mixins/updateUrlMixin'

import labelEditable from '@/components/labelEditable'

const FEATURE_NEW_DEFAULT = {
	name: '',
	description: '',
	userIds: '',
	clientIds: ''
}

export default {
	name: 'features',
	metaInfo () {
		return {
			title: this.title
		}
	},
	mixins: [updateUrlMixin],
	components: {
		'v-label-editable': labelEditable
	},
	data () {
		return {
			title: 'Доступ к фичам',
			loading: false,
			headers: [
				{
					text: 'ID фичи',
					value: 'id',
					align: 'center',
					sortable: false,
					divider: true
				},
				{
					text: 'Название фичи',
					value: 'name',
					align: 'center',
					sortable: false,
					divider: true
				},
				{
					text: 'Описание',
					value: 'description',
					align: 'center',
					sortable: false,
					divider: true
				},
				{
					text: 'ID',
					value: 'ids',
					align: 'center',
					sortable: false,
					divider: true,
					cellClass: 'features-cell-ids'
				},
				{
					text: 'Действие',
					value: 'actions',
					align: 'center',
					sortable: false
				}
			],
			isDialogCreate: false,
			isFormValid: true,
			featureNew: { ...FEATURE_NEW_DEFAULT },
			isCreateFeatureInProgress: false,
			itemsPerPageOptions: [5, 15, 25, 50, 100],
			featureToDeleteId: null,
			isDialogDelete: false,
			isDeleteFeatureInProgress: false
		}
	},
	computed: {
		...mapState({
			features: state => state.features.features,
			total: state => state.features.total
		}),
		urlFilters () {
			const routeQuery = this.$route.query
			const filters = {
				page: undefined,
				limit: undefined
			}
			filters.page = validateFilterNumber(+routeQuery.page, 1)
			filters.limit = validateFilterFromOptions(+routeQuery.limit, this.itemsPerPageOptions, 100)
			return filters
		},
		rules () {
			return {
				required: value => !!value || 'Поле обязательно',
				maxLength: value => value.length <= 30 || 'Максимальная длина - 30 символов',
				arrayNumber: value => value.split(' ').every(item => !isNaN(+item)) || 'Некорректный ID',
				uniqueName: value => !this.features.find(feature => feature.name === value) || 'Название уже занято'
			}
		}
	},
	watch: {
		$route (to, from) {
			const queryTo = to.query
			const queryFrom = from.query
			if (
				queryTo.page !== queryFrom.page ||
				queryTo.limit !== queryFrom.limit
			) {
				this.initData()
			}
		}
	},
	created () {
		this.initData()
	},
	methods: {
		...mapActions({
			getFeatures: 'features/getFeatures',
			createFeature: 'features/createFeature',
			updateFeature: 'features/updateFeature',
			deleteFeature: 'features/deleteFeature'
		}),
		async initData () {
			this.loading = true
			await this.getFeatures({
				query: {
					limit: this.urlFilters.limit,
					offset: (this.urlFilters.page - 1) * this.urlFilters.limit
				}
			})
			this.loading = false
		},
		resetForm () {
			this.featureNew = { ...FEATURE_NEW_DEFAULT }
			this.$refs.form.resetValidation()
			this.isDialogCreate = false
		},
		async submitForm () {
			this.$refs.form.validate()
			if (this.isFormValid) {
				this.isCreateFeatureInProgress = true
				const result = await this.createFeature(this.prepareCreateFeatureBody())
				this.isCreateFeatureInProgress = false
				if (result) {
					this.initData()
					this.resetForm()
				}
			}
		},
		prepareCreateFeatureBody () {
			const body = { name: this.featureNew.name }
			if (this.featureNew.description) {
				body.description = this.featureNew.description
			}
			if (this.featureNew.userIds) {
				body.user_ids = this.prepareArrayIds(this.featureNew.userIds)
			}
			if (this.featureNew.clientIds) {
				body.client_ids = this.prepareArrayIds(this.featureNew.clientIds)
			}
			return body
		},
		prepareArrayIds (idsStr) {
			return idsStr
				.split(' ')
				.map(item => +item)
				.filter(_ => _)
		},
		prepareStringIds (ids) {
			return Array.isArray(ids) ? ids.join(' ') : ''
		},
		async updateFeatureData (id, key, value) {
			if (id) {
				const body = key === 'user_ids' || key === 'client_ids'
					? { [key]: this.prepareArrayIds(value) }
					: { [key]: value }
				await this.updateFeature({ id, body })
			}
		},
		openDialogDelete (id) {
			this.featureToDeleteId = id
			this.isDialogDelete = true
		},
		closeDialogDelete () {
			this.featureToDeleteId = null
			this.isDialogDelete = false
		},
		async confirmFeatureDeletion () {
			if (this.featureToDeleteId) {
				this.isDeleteFeatureInProgress = true
				const result = await this.deleteFeature(this.featureToDeleteId)
				this.isDeleteFeatureInProgress = false
				if (result) {
					this.initData()
				}
			}
			this.closeDialogDelete()
		},
		updateItemsPerPage (limit) {
			this.updateUrl({
				page: undefined,
				limit
			})
		}
	}
}
</script>

<style scoped lang="scss">
.features-table-top {
	border-bottom: 1px solid rgba(0, 0, 0, .12);
}
.features-ids {
	display: grid;
	grid-template-columns: 160px auto;
	grid-template-rows: repeat(2, auto);
	text-align: left;

	> div:nth-child(2n - 1) {
		border-right: 1px solid rgba(0, 0, 0, .12);
	}

	> div:first-child,
	> div:nth-child(2) {
		border-bottom: 1px solid rgba(0, 0, 0, .12);
	}
}
</style>

<style lang="scss">
.features-cell-ids {
	padding: 0 !important;
}
</style>
