<template>
	<v-form
		v-if="edit"
		ref="form"
		v-model="isFormValid"
		@keydown.esc="edit = false"
		@keydown.enter="editName"
	>
		<v-row>
			<v-combobox
				v-if="options && options.length"
				v-model="valueTemp"
				class="label-edit__field"
				v-bind="{
					items: options,
					itemText,
					returnObject,
					rules,
					hideDetails: 'auto',
					dense: true,
					autofocus: true
				}"
				:search-input.sync="search"
			>
				<template
					v-for="(_, name) in $scopedSlots"
					#[name]="{index, item}"
				>
					<slot
						:name="name"
						:index="index"
						:item="item"
					/>
				</template>
			</v-combobox>
			<v-text-field
				v-else
				v-model="valueTemp"
				class="label-edit__field"
				autofocus
				hide-details="auto"
				dense
				width="100px"
				:rules="rules"
			/>
		</v-row>
		<v-row>
			<v-spacer/>
			<v-btn
				icon
				x-small
				:disabled="loading"
				@click="cancel"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-btn
				class="label-edit-confirm"
				icon
				x-small
				:disabled="!isFormValid || loading"
				:loading="loading"
				@click="editName"
			>
				<v-icon>mdi-check</v-icon>
			</v-btn>
		</v-row>
	</v-form>
	<div
		v-else
		class="name-value"
		@click="edit = true"
	>
		<slot
			name="fake-value"
			:item="valueTemp"
		>
			<span>{{ value }}</span>
		</slot>
		<v-icon
			class="ml-2"
			small
			color="grey"
		>
			mdi-pencil
		</v-icon>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: [String, Number],
			default: null
		},

		options: {
			type: Array,
			default: () => ([])
		},
		itemText: {
			type: String,
			default: 'name'
		},
		returnObject: {
			type: Boolean,
			default: false
		},
		rules: Array,
		onInput: {
			type: Function,
			default: function (value) {
				this.$emit('input', value)
			}
		}
	},

	data () {
		return {
			edit: false,
			valueTemp: undefined,
			search: null,
			isFormValid: true,
			loading: false
		}
	},

	created () {
		this.valueTemp = this.value
	},

	methods: {
		async editName () {
			this.$refs.form.validate()
			if (this.isFormValid) {
				this.loading = true
				await this.onInput(this.valueTemp)
				this.loading = false
				this.edit = false
			}
			this.valueTemp = this.value
		},
		cancel () {
			this.edit = false
			this.valueTemp = this.value
		}
	}
}
</script>

<style scoped lang="scss">
.label {
	&-edit {
		&__field {
			font-size: 14px;
		}
		&-confirm /deep/ .v-btn__loader {
			top: 50%;
			left: 50%;
			width: 60%;
			height: 60%;
			transform: translate(-50%, -50%);
		}
	}
	&-value {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
}
</style>
