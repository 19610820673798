/*
 * Функция генерации нового URL
 */
export function generateRoute (thisTo) {
	const to = {}

	if (thisTo.name) {
		// Vue.set(to, 'name', thisTo.name)
		to.name = JSON.parse(JSON.stringify(thisTo.name))
	}

	if (thisTo.params) {
		// Vue.set(to, 'params', thisTo.params)
		to.params = JSON.parse(JSON.stringify(thisTo.params))
	}

	if (thisTo.query) {
		// Vue.set(to, 'query', thisTo.query)
		to.query = JSON.parse(JSON.stringify(thisTo.query))
	}

	return to
}
