import { render, staticRenderFns } from "./labelEditable.vue?vue&type=template&id=ed5639da&scoped=true"
import script from "./labelEditable.vue?vue&type=script&lang=js"
export * from "./labelEditable.vue?vue&type=script&lang=js"
import style0 from "./labelEditable.vue?vue&type=style&index=0&id=ed5639da&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed5639da",
  null
  
)

export default component.exports