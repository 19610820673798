import { generateRoute } from '@/function/generateRoute'

export default {
	methods: {
		updateUrl (query, replace) {
			const newRoute = generateRoute(this.$route)
			const isEqual = function (query) {
				return !Object
					.keys(query)
					.some(key => (newRoute.query[key] || newRoute.query[key] === '0' || query[key] || query[key] === '0') && String(newRoute.query[key]) !== String(query[key]))
			}

			if (!isEqual(query)) {
				this.$router[replace ? 'replace' : 'push']({
					query: Object.assign(newRoute.query, query),
					params: { noscroll: true }
				})
			}
		}
	}
}
