var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.edit)?_c('v-form',{ref:"form",on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.edit = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.editName.apply(null, arguments)}]},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[(_vm.options && _vm.options.length)?_c('v-combobox',_vm._b({staticClass:"label-edit__field",attrs:{"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function({index, item}){return [_vm._t(name,null,{"index":index,"item":item})]}}})],null,true),model:{value:(_vm.valueTemp),callback:function ($$v) {_vm.valueTemp=$$v},expression:"valueTemp"}},'v-combobox',{
				items: _vm.options,
				itemText: _vm.itemText,
				returnObject: _vm.returnObject,
				rules: _vm.rules,
				hideDetails: 'auto',
				dense: true,
				autofocus: true
			},false)):_c('v-text-field',{staticClass:"label-edit__field",attrs:{"autofocus":"","hide-details":"auto","dense":"","width":"100px","rules":_vm.rules},model:{value:(_vm.valueTemp),callback:function ($$v) {_vm.valueTemp=$$v},expression:"valueTemp"}})],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","x-small":"","disabled":_vm.loading},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{staticClass:"label-edit-confirm",attrs:{"icon":"","x-small":"","disabled":!_vm.isFormValid || _vm.loading,"loading":_vm.loading},on:{"click":_vm.editName}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1):_c('div',{staticClass:"name-value",on:{"click":function($event){_vm.edit = true}}},[_vm._t("fake-value",function(){return [_c('span',[_vm._v(_vm._s(_vm.value))])]},{"item":_vm.valueTemp}),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"grey"}},[_vm._v(" mdi-pencil ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }