import { isNaN, isNull, isObjectLike } from 'lodash'

function validateArray (value, options) {
	return value.filter(item => {
		return item !== null &&
			item !== undefined &&
			findOption(item, options)
	})
}

function findOption (value, options) {
	return options.find(option => {
		return isObjectLike(option)
			? option.id === value
			: option === value
	})
}

export function validateFilterNumber (value, defaultValue) {
	if (!isNull(value) && !isNaN(value) && value !== undefined) {
		return value
	} else if (!isNull(defaultValue) && defaultValue !== undefined) {
		return defaultValue
	} else {
		return undefined
	}
}

export function validateFilterFromOptions (value, options = [], defaultValue, multiple) {
	if (multiple) {
		if (value) {
			if (Array.isArray(value)) {
				return validateArray(value, options)
			} else {
				return validateArray([value], options)
			}
		} else {
			return defaultValue || []
		}
	} else {
		if (value !== undefined && findOption(value, options)) {
			return value
		} else if (defaultValue !== undefined) {
			return defaultValue
		} else {
			return undefined
		}
	}
}
